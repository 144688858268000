import { createApi } from '@reduxjs/toolkit/query/react';

const BASE_URL = 'https://api.zapper.fi';
const API_KEY = process.env.REACT_APP_ZAPPER_KEY;

export interface ZapperProtocol {
  img?: string;
  appId: string;
  balances: ZapperBalances;
  network: string;
}

export interface ZapperMeta {
  label: string;
  type: string;
  value: number;
}
interface ZapperProduct {
  label: string;
  meta: any[];
  assets: ZapperAsset[];
}
interface ZapperAsset {
  address: string;
  balanceUSD: number;
  label: string;
  balance: number;
  img: string;
  hide: boolean;
  symbol: string;
  price: number;
  appId: string;
  type: string;
  decimals?: string;
  balanceRaw: string;
  tokens?: ZapperToken[];
}
interface ZapperToken {
  address: string;
  appId: string;
  apy?: number;
  balance: number;
  balanceRaw: string;
  balanceUSD: number;
  decimals: number;
  label: string;
  network: string;
  tokenImageUrl: string;
  metaType: string;
  supply: string;
  symbol: string;
  price: number;
  type: string;
}

export interface ZapperBalance {
  meta: ZapperMeta[];
  products: ZapperProduct[];
}

export interface ZapperBalances {
  readonly [address: string]: ZapperBalance;
}

// TXN API
export interface TxnHistoryResponse {
  error: string[];
  data: Txn[];
}

export interface Txn {
  network: string;
  hash: string;
  blockNumber: number;
  name: string;
  direction: string;
  timeStamp: string;
  symbol: string;
  address: string;
  amount: string;
  from: string;
  destination: string;
  contract: string;
  subTransactions: SubTransaction[];
  nonce: string;
  gasPrice: number;
  gasLimit: number;
  input: string;
  gas: number;
  txSuccessful: boolean;
  account: string;
  // destinationEns: string | undefined
  // accountEns: string | undefined
}

export interface SubTransaction {
  type: string;
  symbol: string;
  amount: number;
  address: string;
}

export const getWalletInfo = async (addresses: string[]) => {
  try {
    const addressesStr = addresses
      .reduce(
        (str, address) =>
          str.concat(`&addresses%5B%5D=${address.toLowerCase()}`),
        '',
      )
      .substring(1);
    const supportedProtocolURL = `${BASE_URL}/v2/apps/balances/supported?${addressesStr}`;
    const networks = await fetch(supportedProtocolURL, {
      credentials: 'include',
      headers: {
        Authorization: `Basic ${Buffer.from(API_KEY + ':' || ':').toString(
          'base64',
        )}`,
      },
    }).then((res) => res.json());
    const networkBalances = await Promise.all(
      networks.map(async (network: any) => {
        const apps = network.apps;
        const protocolInfos: ZapperProtocol[] = await Promise.all(
          apps.map(async (app: any) => {
            const protocolBalances: ZapperBalances = await getProtocolBalances(
              app.appId,
              network.network,
              addresses,
            );
            const { img } = app.meta;
            return {
              img,
              network: network.network,
              appId: app.appId,
              balances: protocolBalances,
            };
          }),
        );
        return protocolInfos;
      }),
    );
    return networkBalances.flat();
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getProtocolBalances = async (
  appId: any,
  network: any,
  addresses: string[],
): Promise<any> => {
  try {
    const addressesStr = addresses.reduce(
      (str, address) => str.concat(`&addresses%5B%5D=${address.toLowerCase()}`),
      '',
    );

    const ProtocolBalance = `${BASE_URL}/v2/apps/${appId}/balances?network=${network}${addressesStr}`;
    const protocolBalance = await fetch(ProtocolBalance, {
      credentials: 'include',
      headers: {
        Authorization: `Basic ${Buffer.from(API_KEY + ':' || ':').toString(
          'base64',
        )}`,
      },
    }).then((res) => res.json());
    return protocolBalance;
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const getInfo = async (address: string) => {
  const URL = `${BASE_URL}/v1/balances?addresses%5B%5D=${address}&api_key=${API_KEY}`;
  let response = await fetch(URL).then((response) => response.text());
  var sanitized =
    '[' + response.replace(/^\s+|\s+$|\s+(?=\s)/g, ',').slice(0, -1) + ']';
  return JSON.parse(sanitized);
};

export const zapperAssetsApi = createApi({
  reducerPath: 'assets-zapper',
  baseQuery: async (addresses) => {
    if (!addresses) {
      return { error: 'No addresses provided' };
    }
    const data = await getWalletInfo(addresses);
    return { data };
  },
  endpoints: (build) => ({
    getZapperAssets: build.query<ZapperProtocol[], string[]>({
      query: (addresses) => addresses,
    }),
  }),
  refetchOnMountOrArgChange: true,
});

const getZapperTxns = async (addresses: string[]): Promise<any> => {
  try {
    const addressesStr = addresses
      .reduce(
        (str, address) =>
          str.concat(`&addresses%5B%5D=${address.toLowerCase()}`),
        '',
      )
      .substring(1);
    const supportedProtocolURL = `${BASE_URL}/v2/transactions?${addressesStr}`;
    const txns = await fetch(supportedProtocolURL, {
      credentials: 'include',
      headers: {
        Authorization: `Basic ${Buffer.from(API_KEY + ':' || ':').toString(
          'base64',
        )}`,
      },
    }).then((res) => res.json());
    return txns;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const zapperTxnApi = createApi({
  reducerPath: 'txns-zapper',
  baseQuery: async (addresses) => {
    if (!addresses) {
      return { error: 'No addresses provided' };
    }
    const data = await getZapperTxns(addresses);
    return { data };
  },
  endpoints: (build) => ({
    getZapperTxns: build.query<TxnHistoryResponse | undefined, string[]>({
      query: (addresses) => addresses,
    }),
  }),
  refetchOnMountOrArgChange: true,
});

const { useGetZapperAssetsQuery } = zapperAssetsApi;
const { useGetZapperTxnsQuery } = zapperTxnApi;
export { useGetZapperAssetsQuery, useGetZapperTxnsQuery };
