import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import application from './application/reducer';
import onboarding from './onboarding/reducer';
import { zapperAssetsApi, zapperTxnApi } from './assets/zapper';
import { ipfsAssetsApi } from './assets/history';
import { backendApi } from './backendApi';

const store = configureStore({
  reducer: {
    application,
    onboarding,
    [zapperAssetsApi.reducerPath]: zapperAssetsApi.reducer,
    [zapperTxnApi.reducerPath]: zapperTxnApi.reducer,
    [ipfsAssetsApi.reducerPath]: ipfsAssetsApi.reducer,
    [backendApi.reducerPath]: backendApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      zapperAssetsApi.middleware,
      zapperTxnApi.middleware,
      ipfsAssetsApi.middleware,
      backendApi.middleware,
    ]),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
