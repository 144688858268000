import { utils } from 'ethers';

export enum ChainId {
  NOT_CONNECTED = '0x0',
  // MAINNET = 1,
  RINKEBY = '0x4',
  GOERLI = '0x5',
  // LOCALHOST = 1337,
  POLYGON = '0x89',
  // MUMBAI = 80001,
}

export const CHAIN_NAMES: { [chainId in ChainId]: string } = {
  [ChainId.NOT_CONNECTED]: 'Polygon',
  // [ChainId.MAINNET]: 'Ethereum',
  [ChainId.RINKEBY]: 'Rinkeby Testnet',
  [ChainId.GOERLI]: 'Goerli Testnet',
  // [ChainId.LOCALHOST]: 'Localhost Testnet',
  [ChainId.POLYGON]: 'Polygon',
  // [ChainId.MUMBAI]: 'Mumbai Testnet',
};

export const CHAIN_TOKEN_NAMES: { [chainId in ChainId]: string } = {
  [ChainId.NOT_CONNECTED]: 'MATIC',
  // [ChainId.MAINNET]: 'ETH',
  [ChainId.RINKEBY]: 'Test ETH',
  [ChainId.GOERLI]: 'Test ETH',
  // [ChainId.LOCALHOST]: 'tETH',
  [ChainId.POLYGON]: 'MATIC',
  // [ChainId.MUMBAI]: 'tMATIC',
};

export const GRAPH_URL: { [chainId in ChainId]: string } = {
  [ChainId.NOT_CONNECTED]:
    'https://api.thegraph.com/subgraphs/name/smart-money-finance/smartfunds-v2-polygon',
  // [ChainId.MAINNET]:
  //   'https://api.thegraph.com/subgraphs/name/smart-money-finance/smart-funds-v2-1-rinkeby',
  //Replace will break
  [ChainId.RINKEBY]:
    'https://api.thegraph.com/subgraphs/name/smart-money-finance/smart-money-v2-rinkeby',
  [ChainId.GOERLI]:
    'https://api.thegraph.com/subgraphs/name/smart-money-finance/smartfunds-v2-goerli',
  // [ChainId.LOCALHOST]:
  //   'https://api.thegraph.com/subgraphs/name/smart-money-finance/smartfunds-v2-mumbai',
  [ChainId.POLYGON]:
    'https://api.thegraph.com/subgraphs/name/smart-money-finance/smartfunds-v2-polygon',
  // [ChainId.MUMBAI]:
  //   'https://api.thegraph.com/subgraphs/name/smart-money-finance/smartfunds-v2-mumbai',
};

export const USDC_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.NOT_CONNECTED]: '0x9a7317071fab80ab63ED97128705F81B185BAB37',
  // [ChainId.MAINNET]: constants.AddressZero,
  [ChainId.RINKEBY]: '0xe3f8c202317F4f273BAf2097DD5bCBd3eBBE9B85',
  [ChainId.GOERLI]: '0x7efb8665493c75C62e0E7EA692154b62ef16c160',
  // [ChainId.LOCALHOST]: constants.AddressZero,
  [ChainId.POLYGON]: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  // [ChainId.MUMBAI]: '0x3B83F6b38612e0E3357Dc9657f01689dD1e8DADf',
};

export const PERMIT_DOMAIN: {
  [chainId in ChainId]: {
    name: string;
    version: string;
    chainId?: string;
    salt?: Uint8Array;
    verifyingContract: string;
  };
} = {
  [ChainId.NOT_CONNECTED]: {
    name: 'USD Coin',
    version: '1',
    chainId: ChainId.NOT_CONNECTED,
    verifyingContract: USDC_ADDRESS[ChainId.NOT_CONNECTED],
  },
  // [ChainId.MAINNET]: {
  //   name: 'USD Coin',
  //   version: '2',
  //   chainId: ChainId.MAINNET,
  //   verifyingContract: USDC_ADDRESS[ChainId.MAINNET],
  // },
  [ChainId.RINKEBY]: {
    name: 'USD Coin',
    version: '1',
    chainId: ChainId.RINKEBY,
    verifyingContract: USDC_ADDRESS[ChainId.RINKEBY],
  },
  [ChainId.GOERLI]: {
    name: 'USD Coin',
    version: '1',
    chainId: ChainId.GOERLI,
    verifyingContract: USDC_ADDRESS[ChainId.GOERLI],
  },
  // [ChainId.LOCALHOST]: {
  //   name: 'USD Coin',
  //   version: '1',
  //   chainId: ChainId.LOCALHOST,
  //   verifyingContract: USDC_ADDRESS[ChainId.LOCALHOST],
  // },
  [ChainId.POLYGON]: {
    name: 'USD Coin (PoS)',
    version: '1',
    salt: utils.zeroPad(utils.arrayify(utils.hexValue(ChainId.POLYGON)), 32),
    verifyingContract: USDC_ADDRESS[ChainId.POLYGON],
  },
  // [ChainId.MUMBAI]: {
  //   name: 'USD Coin',
  //   version: '1',
  //   chainId: ChainId.MUMBAI,
  //   verifyingContract: USDC_ADDRESS[ChainId.MUMBAI],
  // },
};

export const REGISTRY_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.NOT_CONNECTED]: '0x9a7317071fab80ab63ED97128705F81B185BAB37',
  // [ChainId.MAINNET]: constants.AddressZero,
  [ChainId.RINKEBY]: '0x211e43C96dc5618b16FadcA4eF47B304139CE046',
  [ChainId.GOERLI]: '0xd3b917CB90F44ef6fB99C87B5C58525b00503b26',
  // [ChainId.LOCALHOST]: constants.AddressZero,
  [ChainId.POLYGON]: '0x9a7317071fab80ab63ED97128705F81B185BAB37',
  // [ChainId.MUMBAI]: '0x73070bdf7D2FDcf5dA4180b1A92723663f48A17C',
};
