import { useCallback } from 'react';
import {
  utils,
  ContractTransaction,
  ContractReceipt,
  constants,
  BigNumberish,
  Signature,
} from 'ethers';
import toast from 'react-hot-toast';

import { useAppDispatch } from '../state';
import { useRefresh } from '../state/application/hooks';
import { useUSDCTestTokenContract } from './contracts';
import { txSent, txMined } from '../state/application/actions';
import { useActiveWeb3 } from './wallet';
import { PERMIT_DOMAIN } from '../constants';

export const useTestFaucet = () => {
  const USDCToken = useUSDCTestTokenContract();
  const txFeedbackWrapper = useTransactionFeedbackWrapper();

  return useCallback(async () => {
    const txPromise = USDCToken.faucet(utils.parseUnits('100000', 6));
    await txFeedbackWrapper(txPromise, 'USDC faucet');
  }, [USDCToken, txFeedbackWrapper]);
};

/**
 * Hook that provides a function to handle toasts and
 * redux dispatches when having the user send a transaction
 * @returns function that takes a promise of a transaction
 */
export function useTransactionFeedbackWrapper() {
  const dispatch = useAppDispatch();
  const refresh = useRefresh();

  return useCallback(
    async (
      txPromise: Promise<ContractTransaction>,
      txDescription = 'Transaction',
    ) => {
      const id = toast('Confirm transaction in wallet...');
      let tx: ContractTransaction;
      try {
        tx = await txPromise;
      } catch (err) {
        toast.error(`${txDescription} failed`, { id });
        throw err;
      }
      toast.loading(`${txDescription} pending...`, { id });
      dispatch(txSent());
      let resp: ContractReceipt;
      try {
        resp = await tx.wait();
      } catch (err) {
        toast.error(`${txDescription} failed`, { id });
        dispatch(txMined());
        throw err;
      }
      if (resp.status === 0) {
        toast.error(`${txDescription} failed`, { id });
        dispatch(txMined());
        return;
      }
      toast.loading(`${txDescription} succeeded. Refreshing site data...`, {
        id,
      });
      try {
        await refresh(resp.blockNumber);
      } catch (err) {
        toast.error(
          `${txDescription} succeeded but data refresh failed. Please reload the page...`,
        );
        dispatch(txMined());
        throw err;
      }
      toast.success(`${txDescription} succeeded and data refreshed`, { id });
      dispatch(txMined());
    },
    [dispatch, refresh],
  );
}

/**
 * Hook that returns a function that requests signature from wallet for
 * permitting usdc to be transferred to the fund address and
 * returns the signature. Also handles toast feedback
 * @param fundAddress address to permit usdc to
 * @returns function that takes in usdc amount in uint256 units
 */
export function useSignPermit(fundAddress: string) {
  const { address, provider, chainId } = useActiveWeb3();
  const USDCToken = useUSDCTestTokenContract();

  return useCallback(
    async (usdcAmount: BigNumberish) => {
      const nonce = await USDCToken.nonces(address);
      const signPromise = provider
        .getSigner()
        ._signTypedData(
          PERMIT_DOMAIN[chainId],
          {
            Permit: [
              {
                name: 'owner',
                type: 'address',
              },
              {
                name: 'spender',
                type: 'address',
              },
              {
                name: 'value',
                type: 'uint256',
              },
              {
                name: 'nonce',
                type: 'uint256',
              },
              {
                name: 'deadline',
                type: 'uint256',
              },
            ],
          },
          {
            owner: address,
            spender: fundAddress,
            value: usdcAmount,
            nonce,
            deadline: constants.MaxUint256,
          },
        )
        .then(utils.splitSignature);
      return signFeedbackWrapper(
        signPromise,
        'Sign message in wallet to permit USDC transfer...',
      );
    },
    [USDCToken, address, provider, chainId, fundAddress],
  );
}

async function signFeedbackWrapper(
  signPromise: Promise<Signature>,
  toastText = 'Sign message in wallet...',
) {
  const id = toast(toastText);
  let signature: Signature;
  try {
    signature = await signPromise;
  } catch (err) {
    toast.error('Signing message failed', { id });
    throw err;
  }
  toast.success('Signed message', { id });
  return signature;
}
